<template>
  <transition name="slide">
    <v-card
      class="panel"
      color="blue-grey darken-4"
    >
      <v-sheet
        color="transparent"
        elevation="0"
        height="60"
        width="100"
      />
      <v-card-text>
        <v-list color="blue-grey darken-4">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            @click="select(item)"
          >
            {{ item.get('model').title }}
          </v-list-item>
        </v-list>
        <p v-if="!items.length">Ничего не найдено</p>
      </v-card-text>
    </v-card >
  </transition>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    items: {
      type: Array
    }
  },

  methods: {
    ...mapActions({
      selectInCluster: 'gis/selectInCluster'
    }),

    select (feature) {
      this.selectInCluster(feature)
      this.$emit('select')
    }
  }
}

</script>

<style scoped>
.panel {
  position: absolute;
  width: 355px;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 10px;
  z-index: 2;
  opacity: 0.95;
}
.slide-enter-active {
  transition: all .2s ease;
}
.slide-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-enter, .slide-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(300px);
}
</style>
