<template>
  <v-card color="blue-grey darken-4">
    <v-card-text :style="'height: ' + height + 'px; overflow-y:auto'">
      <v-row
        v-for="(mdl, i) in ld" :key="i"
      >
        <v-col cols="12">
          <span class="text-overline animate-val-update" :key="mdl.data.event_dt">
            {{ dtFormat(mdl.data.event_dt) }}
          </span>
        </v-col>
        <v-col cols="6" v-for="(prm, j) in mdl.parameters" :key="j">
          <span class="text-caption blue-grey--text text--lighten-5" >{{ prm.pivot.title }}</span><br/>
          <span class="text-h6 animate-val-update" :key="parseData(mdl, prm)">
            {{ parseData(mdl, prm) }}
            {{ prm.pivot.unit.designation }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-btn
        fab
        outlined
        small
        @click="$emit('togglePlot')"
      >
        <v-icon>mdi-chart-multiline</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

moment.locale('ru')

export default {
  props: {
    item: {
      type: Object
    }
  },

  data: () => ({
    dataUpdating: false,
    ld: []
  }),

  methods: {
    ...mapActions({
      getData: 'devices/data',
      setLastData: 'devices/setLastData'
    }),

    parseData (module, parameter) {
      return module.data.data[parameter.pivot.alias]
    },

    setDataUpdating () {
      this.dataUpdating = true
      setTimeout(() => {
        this.dataUpdating = false
      }, 250)
    },

    dtFormat (dt) {
      return moment.utc(dt).local().format('DD MMMM HH:mm')
    },

    _getData () {
      this.getData({
        id: this.item.get('model').id,
        params: {
          params: { latest: true }
        }
      }).then((response) => {
        this.setLastData({
          deviceId: this.item.get('model').id,
          data: response
        })
        this.ld = response
      })
    }
  },

  watch: {
    item: {
      immediate: true,
      handler () {
        this._getData()
      }
    },

    dataUpdated: {
      handler (val) {
        this.ld = this.lastData[this.item.get('model').id]
      }
    }
  },

  computed: {
    ...mapGetters({
      lastData: 'devices/lastData',
      dataUpdated: 'devices/dataUpdated'
    }),

    watcherName () {
      return 'lastData.' + this.item.get('model').id + '.data.event_dt'
    },

    height: () => (window.innerHeight - 185)
  }
}
</script>

<style>
.animate-val-update {
  color: '#ECEFF1';
  animation: animate-upd .3s;
}
@keyframes animate-upd {
  1% { color: #ECEFF1; zoom: 100%; }
  20% { color: #FFE0B2; zoom: 120%; }
  30% { zoom: 115%; }
  40% { zoom: 110%; }
  50% { zoom: 105%; }
  60% { zoom: 100%; }
  99% { color: #ECEFF1}
}
</style>
