<template>
  <transition name="slide">
    <v-card
      class="panel"
      color="blue-grey darken-4"
    >
      <v-sheet
        color="transparent"
        elevation="0"
        height="60"
        width="100"
      />
      <v-tabs
        v-model="tab"
        grow
        color="blue-grey lighten-5"
        background-color="transparent"
      >
        <v-tab v-for="(item, i) in items" :key="i">
          {{ item.get('model').title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" v-if="hasSelection">
        <v-tab-item v-for="(item, i) in items" :key="i">
          <item
            :item="item"
            @togglePlot="$emit('togglePlot')"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card >
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Item from './Item'

export default {
  components: {
    Item
  },

  data: () => ({
    tab: null,
    items: []
  }),

  methods: {
    ...mapActions({
      setActiveDevice: 'gis/setActiveDevice'
    })
  },

  watch: {
    selected () {
      this.items = this.hasSelection ? this.selected[0].get('features') : []
      this.setActiveDevice(this.items[this.tab])
    },

    tab: {
      immediate: true,
      handler () {
        this.setActiveDevice(this.items[this.tab])
      }
    }
  },

  computed: {
    ...mapGetters({
      selected: 'gis/selected',
      hasSelection: 'gis/hasSelection'
    })
  }
}

</script>

<style scoped>
.panel {
  position: absolute;
  width: 355px;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 10px;
  z-index: 3;
  opacity: 0.97;
}
.slide-enter-active {
  transition: all .2s ease;
}
.slide-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-enter, .slide-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(300px);
}
</style>
