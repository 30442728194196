<template>
  <div id="gisMap" class="map fill-height" style="width: 100%; height: 100%; background-color: #37474F">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <search
      v-model="sstr"
      @found="setSR"
    />

    <device
      v-show="showPanel"
      @togglePlot="plot = !plot"
    />

    <search-result
      :items = "searchRes"
      v-show="sstr.length"
      @select="setSelect"
    />

    <plot
      v-show = "showPlotPanel"
      @hidePlot="plot = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Search from '@/components/Gis/Panels/Search/Index'
import SearchResult from '@/components/Gis/Panels/Search/Result'
import Device from '@/components/Gis/Panels/Device/Index'
import Plot from '../../components/Gis/Panels/Device/Plot'

export default {
  components: {
    Search,
    SearchResult,
    Device,
    Plot
  },

  methods: {
    ...mapActions({
      setMap: 'gis/setMap',
      unsetMap: 'gis/unsetMap',
      loadDevices: 'gis/loadDevices',
      getUserPosition: 'gis/getUserPosition'
    }),

    setSR (results) {
      this.searchRes = results
    },

    setSelect () {
      this.searchRes = []
      this.sstr = ''
    }
  },

  data: () => ({
    loading: true,
    sstr: '',
    plot: false,
    searchRes: []
  }),

  mounted () {
    this.setMap()
    this.loadDevices().then(() => { this.loading = false })
  },

  beforeDestroy () {
    this.unsetMap()
  },

  watch: {
    showPanel (v) {
      if (!v) this.plot = false
    }
  },

  computed: {
    ...mapGetters({
      map: 'gis/map',
      hasSelection: 'gis/hasSelection'
    }),

    showPanel () {
      return this.hasSelection && !this.sstr.length
    },

    showPlotPanel () {
      return this.showPanel && this.plot
    }
  }
}
</script>

<style>

</style>
