<template>
  <transition name="slide">
    <v-card
      class="plot-panel"
      color="blue-grey darken-4"
    >
      <v-toolbar color="blue-grey darken-3" flat>
        <v-toolbar-title>Временная диаграмма</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            icon
            @click="settings = true"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="$emit('hidePlot')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text :style="'max-height: ' + plotHeight + 'px; overflow-y: auto;'">
        <plot
          :data = "data"
          :schema = "preset.schema"
          :getLatestData = "getLatestData"
        />
      </v-card-text>
      <plot-settings
        v-model="settings"
        :device="device ? device.get('model') : {}"
        :preset="preset ? preset : {}"
        @close="settings = false"
        @save="afterSaveSettings"
      />
    </v-card>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Plot from '@/components/Common/Plots/TimeSeries.vue'
import PlotSettings from '@/components/Settings/Plots/Device'

export default {
  components: {
    PlotSettings,
    Plot
  },

  data: () => ({
    preset: {},
    data: [],
    settings: false,
    interval: null
  }),

  methods: {
    ...mapActions({
      getData: 'devices/data',
      getPreset: 'plotPresets/index'
    }),

    afterSaveSettings () {
      this.settings = false
      this.plot()
    },

    plot () {
      if (!this.device) return

      Promise.all([
        this.getData({
          id: this.device.get('model').id,
          params: {
            params: { latest: false }
          }
        }),
        this.getPreset({
          params: {
            device_id: this.device.get('model').id,
            default: true
          }
        })
      ]).then(([data, preset]) => {
        this.preset = preset || {}
        this.data = data
      })
    },

    getLatestData () {
      return this.device ? this.lastData[this.device.get('model').id] : null
    }
  },

  watch: {
    device: {
      immediate: true,
      handler () {
        this.plot()
      }
    }
  },

  computed: {
    ...mapGetters({
      device: 'gis/activeDevice',
      lastData: 'devices/lastData'
    }),

    plotHeight () {
      return window.innerHeight - 110
    }
  }
}
</script>

<style>
.plot-panel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 365px;
  margin: 10px;
  z-index: 2;
  opacity: 0.97;
}
.slide-enter-active {
  transition: all .2s ease;
}
.slide-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-enter, .slide-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(-500px);
}
</style>
