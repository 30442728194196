<template>
  <v-card
    class="search-panel"
    light
  >
    <v-text-field
      dense
      outlined
      autocomplete="off"
      prepend-inner-icon="mdi-magnify"
      hide-details
      v-model="val"
      clearable
      @click:clear="clear"
      @input="search"
    >
    </v-text-field>
  </v-card >
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: String
    }
  },

  data: () => ({
    val: ''
  }),

  methods: {
    ...mapActions({
      _search: 'gis/searchDevice'
    }),

    clear () {
      this.val = ''
      this.$emit('input', this.val)
    },

    search () {
      this._search(this.val).then(result => {
        this.$emit('found', result)
      })
      this.$emit('input', this.val)
    }
  },

  watch: {
    value: {
      immediate: true,
      handler () {
        this.val = this.value
      }
    }
  }
}
</script>

<style scoped>
.search-panel {
  position: absolute;
  width: 325px;
  top: 0;
  right: 0;
  margin: 25px;
  z-index: 10;
  opacity: 0.95;
}
</style>
